<template>
  <div class="view view-home">
    <div class="main center">
      <h1>👋 Hey! I’m <em>Nico Cunningham</em></h1>


      <p>I’m a Senior UX Lead & Product Designer with +15 years of end-to-end experience in UX Research, Design, Development and Product Management.</p>

      <p>I’m currently based in 🇪🇸 <strong>Madrid, Spain</strong></p>

      <p>Since 2015 I’ve been managing cross-functional teams to build products people love such as:</p>
      <ul class="no-bullets">
        <li>💧 <a href="https://waterplan.com" target="_blank">Waterplan.com</a> (Product Designer)</li>
        <li>💎 <a href="https://getproud.io" target="_blank">GetProud.io</a> (CEO & Co-founder)</li>
        <li>☕ <a href="https://coffeebreakgame.com" target="_blank">CoffeeBreakGame.com</a> (CEO & Co-founder)</li>
        <li>💰 <a href="https://themilliondollargame.co" target="_blank">TheMillionDollarGame.co</a> (CTO)</li>
        <li>🗺️ <a href="https://quizviajero.com" target="_blank">QuizViajero.com</a> (CTO & Co-founder)</li>
        <li>🎭 <a href="https://teatrix.com" target="_blank">Teatrix.com</a> (Product Manager & UX Lead)</li>
        <li>🧟 <a href="https://www.pandaancha.mx/noticias/peliculas-de-terror-en-linea-que-es-purga-tv-cine-de-terror.html" target="_blank">Purga.tv</a> (Product Manager & UX Lead)</li>
        <li>🏟️ <a href="https://tecnogaming.com/yasports-es-la-nueva-plataforma-ondemand-para-deportes/" target="_blank">YaSports.com</a> (Product Manager & UX Lead)</li>
        <li>⚽ <a href="https://lineupbuilder.com" target="_blank">LineupBuilder.com</a> (Creator)</li>
        <li>➕ <router-link to="/cases">View more in Portfolio...</router-link></li>
      </ul>

      <p>Between 2008 and 2015 I’ve been working with some of the most influential professionals in the <strong>UX Industry in Argentina</strong> (<a href="https://www.linkedin.com/in/santiagobustelo" target="_blank">Santiago Bustelo</a>, <a href="https://www.linkedin.com/in/smfqv" target="_blank">Sebastián Fernandez Quezada</a> and <a href="https://www.linkedin.com/in/juanmanuelcarraro" target="_blank">Juan Manuel Carraro</a>). I had the opportunity to work as a UX Consultant for more than 50 companies of all sizes and kinds and led hundreds of UX Research studies such as moderated usability tests, card sorting, user interviews, field studies, data analytics and surveys.</p>

      <p>I have experience both in B2C and B2B digital startups, SaaS, Gaming, Travel, Video On-Demand, Admin panels, Web3, NFTs, Crypto, Design (Figma, Wireframing, UX/UI Design), Full-stack Development, Data Analytics, User Acquisition, Monetization, building complex dashboards for real-time KPI tracking, Agile Methodologies, Designing appealing and effective presentations, Budgeting, Recruiting and Team Building, Async Collaboration.</p>

      <p>If you want to learn more about my work, check the <router-link to="/cases">Portfolio section</router-link>.</p>

      <p>If you want to participate in paid user research studies that I do to help website and app owners design more friendly and intuitive products, <a href="https://www.getrevue.co/profile/nicocunn" target="_blank">subscibe to my newsletter</a>.</p>

      <p><strong>I'm always available for new challenges.</strong></p>

      <p>Contact me through <a href="https://www.linkedin.com/in/nicolascunn/" target="_blank">LinkedIn</a> or <a href="https://twitter.com/NicoCunn" target="_blank">Twitter</a>.</p>

      <img class="image-frame" src="/assets/profile.jpg" />

    </div><!--/main-->
  </div><!--/view-home-->
</template>

<script>
</script>
